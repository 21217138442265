/**
 * Trigger a callback when the DOM is loaded, compatible with asynchronous JavaScript
 * @param {function} callback
 */
function onLoadAsync(callback) {
  // The DOM content may already be loaded, since the js is loaded asynchronously
  if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', callback);
  } else {
    callback();
  }
}

export default onLoadAsync;
